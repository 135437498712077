.event-members-table {
  width: 80%;
  font-family: Arial, sans-serif;
  border-collapse: collapse;
}

.event-members-table table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.event-members-table th,
.event-members-table td {
  border: 1px solid #f3f3f3;
  padding: 8px;
  text-align: left;
}

.event-members-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.event-members-table .divider td {
  border: none;
  padding: 0;
  background-color: #f4f4f4;
}

.event-members-table button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-left: 5px;
}

.event-members-table .add-bizbucks {
  background-color: #007bff;
  color: white;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.event-members-table .add-bizbucks-disabled {
  background-color: #a7a6a9;
  color: white;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.event-members-table .add-bizbucks:hover {
  background-color: #0056b3;
}

.company-info {
  display: flex;
  align-items: center;
}

.company-logo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.bizbucks-container {
  background-color: #0078be;
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  color: white;
  /* width: 300px; */
  margin: auto;
}

.bizbucks-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bizbucks-badge {
  background-image: url("../../../assets/illustrations/6.png");
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  min-width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bizbucks-count {
  font-size: 24px;
}

.bizbucks-amount {
  font-size: 48px;
  line-height: normal;
  font-weight: bold;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
}

.bizbucks-button-container {
  padding: 20px;
  text-align: center;
  margin: auto;
}

.bizbucks-button {
  background-color: #5cace5;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  color: white;
  font-size: 18px;
  /* margin-top: 20px; */
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.bizbucks-button:hover {
  background-color: #0078be;
}

@media (max-width: 575px) {
  .bizbucks-amount {
    font-size: 38px;
  }
}
