@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  font-family: "Poppins", sans-serif;
  cursor: default; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

/* The following typography styles are according to the Figma provided  */
::-webkit-scrollbar {
  width: 2px;
  height: 1px; }

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #bdbaba;
  border-radius: 10px; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #039be5;
  border-radius: 10px; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #039be5; }

.rdw-editor-main {
  max-height: 180px;
  overflow-y: auto; }

.divider {
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 1px;
  background-color: transparent; }

.authShadow {
  box-shadow: 0px 2px 14px rgba(0, 4, 43, 0.1); }

.inputField:focus {
  border-color: "#5A5ABB" !important; }

.app-top-nav {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05); }

.side-nav {
  width: 128px;
  background: #ffffff;
  box-shadow: 0px 4px 12px 2px rgba(0, 0, 0, 0.15);
  z-index: 20; }

.box-modal1 {
  width: 41rem !important;
  margin-top: 13%;
  margin-bottom: 5%;
  margin-left: 28%;
  border-radius: 15px !important;
  box-shadow: 0px 0px 12px 10px rgba(0, 0, 0, 0.1) !important;
  background-color: white;
  padding: 1.5rem; }
  @media screen and (max-width: 420px) {
    .box-modal1 {
      width: 100vw !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      margin-left: 0 !important; } }
  @media screen and (min-width: 421px) and (max-width: 768px) {
    .box-modal1 {
      width: 41rem !important;
      margin-top: 5%;
      margin-bottom: 5%;
      margin-left: 8%; } }
  @media screen and (min-width: 769px) and (max-width: 1080px) {
    .box-modal1 {
      width: 41rem !important;
      margin-top: 5%;
      margin-bottom: 5%;
      margin-left: 12%; } }
  .box-modal1-row1 {
    color: #8e8e8e;
    padding-top: 2%;
    font-size: 1.25rem !important;
    font-weight: 500 !important;
    display: flex;
    justify-content: center; }
  .box-modal1-row2 {
    display: flex;
    justify-content: center;
    gap: 1.875rem;
    padding-top: 3%;
    padding-bottom: 1%; }
    .box-modal1-row2-yes {
      width: 8.5rem;
      height: 2.6rem;
      background-color: #039be5;
      font-size: 1.25rem !important;
      font-weight: 700 !important;
      border-radius: 8px;
      color: white; }
    .box-modal1-row2-no {
      width: 8.5rem;
      height: 2.6rem;
      border: 1px solid #039be5;
      font-size: 1.25rem !important;
      font-weight: 700 !important;
      color: #039be5;
      border-radius: 8px; }

.box-modal {
  width: 41rem !important;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 28%;
  border-radius: 15px !important;
  box-shadow: 0px 0px 12px 10px rgba(0, 0, 0, 0.1) !important;
  background-color: white;
  padding: 1.5rem; }
  @media screen and (max-width: 420px) {
    .box-modal {
      width: 100vw !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      margin-left: 0 !important; } }
  @media screen and (min-width: 421px) and (max-width: 768px) {
    .box-modal {
      width: 41rem !important;
      margin-top: 5%;
      margin-bottom: 5%;
      margin-left: 8%; } }
  @media screen and (min-width: 769px) and (max-width: 1080px) {
    .box-modal {
      width: 41rem !important;
      margin-top: 5%;
      margin-bottom: 5%;
      margin-left: 12%; } }
  .box-modal-header {
    font-size: 2.25rem !important;
    font-weight: 500 !important; }
    @media screen and (max-width: 420px) {
      .box-modal-header {
        font-size: 1.25rem !important; } }
  .box-modal-label {
    color: #616161;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0.45rem !important; }
  .box-modal-field {
    border-radius: 8px;
    border: 1px solid #818181 !important;
    width: 100%;
    height: 3.125rem;
    padding-left: 10px; }
  .box-modal-radiolabel {
    color: #5a5a5a !important; }
  .box-modal-textarea {
    border-radius: 8px;
    border: 1px solid #818181 !important;
    width: 100%;
    height: 11rem;
    padding-left: 10px; }
  .box-modal-helper {
    color: #474747;
    font-size: 0.75rem !important;
    font-weight: 400; }
  .box-modal-lastrow {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .box-modal-lastrow-button1 {
      width: 8.5rem;
      height: 2.5rem;
      border-radius: 8px;
      font-size: 1.25rem;
      font-weight: 700;
      color: #039be5;
      border: 1px solid #039be5; }
    .box-modal-lastrow-button2 {
      width: 8.5rem;
      height: 2.5rem;
      border-radius: 8px;
      font-size: 1.25rem;
      font-weight: 700;
      background-color: #039be5;
      color: white;
      border: 1px solid #039be5; }

.box-modal8 {
  width: 41rem !important;
  margin-top: 13%;
  margin-bottom: 5%;
  margin-left: 28%;
  border-radius: 15px !important;
  box-shadow: 0px 0px 12px 10px rgba(0, 0, 0, 0.1) !important;
  background-color: white;
  padding: 1.5rem; }
  @media screen and (max-width: 420px) {
    .box-modal8 {
      width: 100vw !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      margin-left: 0 !important; } }
  @media screen and (min-width: 421px) and (max-width: 768px) {
    .box-modal8 {
      width: 41rem !important;
      margin-top: 5%;
      margin-bottom: 5%;
      margin-left: 8%; } }
  @media screen and (min-width: 769px) and (max-width: 1080px) {
    .box-modal8 {
      width: 41rem !important;
      margin-top: 5%;
      margin-bottom: 5%;
      margin-left: 12%; } }
  .box-modal8-middleColumn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column !important;
    padding-top: 5%;
    padding-bottom: 5%; }
    .box-modal8-middleColumn-subtext {
      font-size: 1.25rem !important;
      font-weight: 400 !important; }
    .box-modal8-middleColumn-delbttn {
      width: 10.75rem;
      height: 2.6rem;
      border: 1px solid #039be5;
      font-size: 1.25rem !important;
      font-weight: 700 !important;
      color: #039be5;
      border-radius: 8px;
      margin-top: 5%; }

.box-modal5 {
  width: 24.5rem !important;
  margin-top: 9%;
  margin-bottom: 5%;
  margin-left: 38%;
  border-radius: 15px !important;
  box-shadow: 0px 0px 12px 10px rgba(0, 0, 0, 0.1) !important;
  background-color: white;
  padding: 1.5rem; }
  @media screen and (max-width: 420px) {
    .box-modal5 {
      width: 100vw !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      margin-left: 0 !important; } }
  @media screen and (min-width: 421px) and (max-width: 768px) {
    .box-modal5 {
      width: 24.5rem !important;
      margin-top: 9%;
      margin-bottom: 5%;
      margin-left: 18%; } }
  @media screen and (min-width: 769px) and (max-width: 1080px) {
    .box-modal5 {
      width: 24.5rem !important;
      margin-top: 9%;
      margin-bottom: 5%;
      margin-left: 27%; } }
  .box-modal5-row1 {
    color: #8e8e8e;
    padding-top: 2%;
    font-size: 1.25rem !important;
    font-weight: 500 !important;
    display: flex;
    justify-content: center; }
  .box-modal5-row2 {
    display: flex;
    justify-content: center;
    gap: 1.875rem;
    padding-top: 3%;
    padding-bottom: 1%; }
    .box-modal5-row2-cancel {
      width: 16.25rem;
      height: 2.6rem;
      border: 1px solid #039be5;
      font-size: 1.25rem !important;
      font-weight: 700 !important;
      color: #039be5;
      border-radius: 8px; }

.fixed-sub-card {
  height: 262px;
  overflow-y: auto; }

.box-modal4 {
  width: 41rem !important;
  margin-top: 13%;
  margin-bottom: 5%;
  margin-left: 28%;
  border-radius: 15px !important;
  box-shadow: 0px 0px 12px 10px rgba(0, 0, 0, 0.1) !important;
  background-color: white;
  padding: 1.5rem; }
  @media screen and (max-width: 420px) {
    .box-modal4 {
      width: 100vw !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      margin-left: 0 !important; } }
  @media screen and (min-width: 421px) and (max-width: 768px) {
    .box-modal4 {
      width: 41rem !important;
      margin-top: 5%;
      margin-bottom: 5%;
      margin-left: 8%; } }
  @media screen and (min-width: 769px) and (max-width: 1080px) {
    .box-modal4 {
      width: 41rem !important;
      margin-top: 5%;
      margin-bottom: 5%;
      margin-left: 12%; } }
  .box-modal4-lastColumn {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    color: #d4d4d4;
    font-size: 23px; }
  .box-modal4-middleColumn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column !important;
    padding-top: 1.5rem;
    padding-bottom: 2rem; }
    .box-modal4-middleColumn-icon {
      color: #139738;
      font-size: 46px;
      padding-bottom: 0.75rem; }
    .box-modal4-middleColumn-text {
      font-weight: 500 !important;
      font-size: 1.5rem !important;
      padding-bottom: 0.75rem; }
    .box-modal4-middleColumn-codeDiv {
      background-color: #067eed26;
      width: 12.5rem;
      height: 3.125rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.5rem; }
      .box-modal4-middleColumn-codeDiv-code {
        font-weight: 500 !important;
        font-size: 1.5rem !important; }
    .box-modal4-middleColumn-copyLink {
      color: #03a9f4;
      text-decoration: underline;
      font-size: 0.75rem;
      font-weight: 400;
      padding-top: 0.25rem; }
  .box-modal4-header {
    margin-top: 2%;
    color: #5b5b5b !important;
    font-size: 1.5rem !important;
    font-weight: 500 !important; }
    @media screen and (max-width: 420px) {
      .box-modal4-header {
        font-size: 1rem !important; } }
  .box-modal4-label {
    color: #5b5b5b !important;
    font-size: 1.125rem;
    font-weight: 400;
    margin-bottom: 0.45rem !important; }
  .box-modal4-SelectTextfield {
    border-radius: 8px;
    border: 1px solid #0080ff !important;
    color: #737373;
    width: 16.8rem;
    height: 3.125rem;
    padding-left: 10px; }
    .box-modal4-SelectTextfield-options {
      color: #737373 !important; }
  .box-modal4-radioLabel {
    color: #5a5a5a !important; }
  .box-modal4-lastRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5%;
    margin-bottom: 2%; }
    .box-modal4-lastRow-button1 {
      width: 8.5rem;
      height: 2.5rem;
      border-radius: 8px;
      font-size: 1.25rem;
      font-weight: 700;
      color: #039be5;
      border: 1px solid #039be5; }
    .box-modal4-lastRow-button2 {
      width: 8.5rem;
      height: 2.5rem;
      border-radius: 8px;
      font-size: 1.25rem;
      font-weight: 700;
      background-color: #039be5;
      color: white;
      border: 1px solid #039be5; }

.box-modal7 {
  width: 41rem !important;
  margin-top: 13%;
  margin-bottom: 5%;
  margin-left: 28%;
  border-radius: 15px !important;
  box-shadow: 0px 0px 12px 10px rgba(0, 0, 0, 0.1) !important;
  background-color: white;
  padding: 1.5rem; }
  @media screen and (max-width: 420px) {
    .box-modal7 {
      width: 100vw !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      margin-left: 0 !important; } }
  @media screen and (min-width: 421px) and (max-width: 768px) {
    .box-modal7 {
      width: 41rem !important;
      margin-top: 5%;
      margin-bottom: 5%;
      margin-left: 8%; } }
  @media screen and (min-width: 769px) and (max-width: 1080px) {
    .box-modal7 {
      width: 41rem !important;
      margin-top: 5%;
      margin-bottom: 5%;
      margin-left: 12%; } }
  .box-modal7-middleColumn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column !important; }
    .box-modal7-middleColumn-icon {
      color: #139738;
      font-size: 46px;
      padding-bottom: 0.75rem; }
    .box-modal7-middleColumn-mainText {
      font-weight: 500 !important;
      font-size: 1.85rem !important; }
    .box-modal7-middleColumn-subtext {
      font-size: 1.25rem !important;
      font-weight: 400 !important; }
    .box-modal7-middleColumn-okayButton {
      width: 8.5rem;
      height: 2.6rem;
      background-color: #039be5;
      font-size: 1.25rem !important;
      font-weight: 700 !important;
      border-radius: 8px;
      color: white;
      margin-top: 1.5rem; }

.box-modal3 {
  width: 41rem !important;
  margin-top: 13%;
  margin-bottom: 5%;
  margin-left: 28%;
  border-radius: 15px !important;
  box-shadow: 0px 0px 12px 10px rgba(0, 0, 0, 0.1) !important;
  background-color: white;
  padding: 1.5rem; }
  @media screen and (max-width: 420px) {
    .box-modal3 {
      width: 100vw !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      margin-left: 0 !important; } }
  @media screen and (min-width: 421px) and (max-width: 768px) {
    .box-modal3 {
      width: 41rem !important;
      margin-top: 5%;
      margin-bottom: 5%;
      margin-left: 8%; } }
  @media screen and (min-width: 769px) and (max-width: 1080px) {
    .box-modal3 {
      width: 41rem !important;
      margin-top: 5%;
      margin-bottom: 5%;
      margin-left: 12%; } }
  .box-modal3-lastColumn {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    color: #d4d4d4;
    font-size: 23px; }
  .box-modal3-middleColumn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column !important;
    padding-top: 1.5rem;
    padding-bottom: 2rem; }
    .box-modal3-middleColumn-icon {
      color: #139738;
      font-size: 46px;
      padding-bottom: 0.75rem; }
    .box-modal3-middleColumn-text {
      font-weight: 500 !important;
      font-size: 1.25rem !important;
      padding-bottom: 0.75rem; }
    .box-modal3-middleColumn-codeDiv {
      background-color: #edf0f1;
      width: 20rem;
      height: 3.125rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.5rem; }
      .box-modal3-middleColumn-codeDiv-code {
        font-weight: 400 !important;
        font-size: 1rem !important; }
    .box-modal3-middleColumn-subtext {
      color: #5b5b5b;
      font-size: 0.75rem !important;
      font-weight: 400 !important;
      margin-top: 0.5rem; }
  .box-modal3-header {
    margin-top: 2%;
    color: #5b5b5b !important;
    font-size: 1.5rem !important;
    font-weight: 500 !important; }
    @media screen and (max-width: 420px) {
      .box-modal3-header {
        font-size: 1rem !important; } }
  .box-modal3-label {
    color: #5b5b5b !important;
    font-size: 1.125rem;
    font-weight: 400;
    margin-bottom: 0.45rem !important; }
  .box-modal3-lastrow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5%;
    margin-bottom: 2%; }

.box-modal2 {
  width: 41rem !important;
  margin-top: 13%;
  margin-bottom: 5%;
  margin-left: 28%;
  border-radius: 15px !important;
  box-shadow: 0px 0px 12px 10px rgba(0, 0, 0, 0.1) !important;
  background-color: white;
  padding: 1.25rem; }
  @media screen and (max-width: 420px) {
    .box-modal2 {
      width: 100vw !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      margin-left: 0 !important; } }
  @media screen and (min-width: 421px) and (max-width: 768px) {
    .box-modal2 {
      width: 41rem !important;
      margin-top: 5%;
      margin-bottom: 5%;
      margin-left: 8%; } }
  @media screen and (min-width: 769px) and (max-width: 1080px) {
    .box-modal2 {
      width: 41rem !important;
      margin-top: 5%;
      margin-bottom: 5%;
      margin-left: 12%; } }
  .box-modal2-row1 {
    padding-bottom: 3%;
    gap: 1rem;
    display: flex;
    align-items: center;
    justify-content: center; }
    .box-modal2-row1-header {
      color: #5b5b5b;
      font-size: 1.5rem !important;
      font-weight: 400 !important; }
    .box-modal2-row1-iconDiv-icon {
      font-size: 3rem; }
  .box-modal2-row2 {
    padding-bottom: 3%;
    display: flex;
    flex-direction: column !important;
    gap: 0; }
    .box-modal2-row2-label {
      color: #5b5b5b;
      font-size: 0.875rem !important;
      font-weight: 400 !important; }
    .box-modal2-row2-input {
      width: 100%;
      border: 1px solid #d6d6d6;
      height: 2.25rem;
      border-radius: 3px;
      color: #7c7c7c;
      padding-left: 2%; }
  .box-modal2-textarea {
    width: 500px !important;
    border-radius: 10px !important; }
  .box-modal2-row3 {
    background-color: #f0f5fa;
    color: #5b5b5b;
    margin-bottom: 3% !important;
    padding: 2%;
    width: 100% !important;
    border-radius: 10px; }
    .box-modal2-row3-textarea {
      width: 100%;
      resize: both; }
    .box-modal2-row3-text {
      padding-bottom: 1% !important;
      font-size: 0.875rem !important;
      font-weight: 400 !important; }
  .box-modal2-row4 {
    display: flex;
    justify-content: space-between; }
    .box-modal2-row4-yes {
      width: 8.5rem;
      height: 2.6rem;
      background-color: #039be5;
      font-size: 1.25rem !important;
      font-weight: 700 !important;
      border-radius: 8px;
      color: white; }
    .box-modal2-row4-no {
      width: 8.5rem;
      height: 2.6rem;
      border: 1px solid #039be5;
      font-size: 1.25rem !important;
      font-weight: 700 !important;
      color: #039be5;
      border-radius: 8px; }

.box-modal6 {
  max-width: 29rem !important;
  margin-top: 13%;
  margin-bottom: 5%;
  margin-left: 35%;
  border-radius: 15px !important;
  box-shadow: 0px 0px 12px 10px rgba(0, 0, 0, 0.1) !important;
  background-color: white;
  padding: 1.5rem; }
  @media screen and (max-width: 420px) {
    .box-modal6 {
      width: 100vw !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      margin-left: 0 !important; } }
  @media screen and (min-width: 421px) and (max-width: 768px) {
    .box-modal6 {
      width: 41rem !important;
      margin-top: 5%;
      margin-bottom: 5%;
      margin-left: 15%; } }
  @media screen and (min-width: 769px) and (max-width: 1080px) {
    .box-modal6 {
      width: 41rem !important;
      margin-top: 5%;
      margin-bottom: 5%;
      margin-left: 25%; } }
  .box-modal6-lastRow {
    display: flex;
    justify-content: space-between;
    padding-top: 8%;
    padding-bottom: 1%; }
    .box-modal6-lastRow-yes {
      width: 11rem;
      height: 2.6rem;
      background-color: #039be5;
      font-size: 1.25rem !important;
      font-weight: 700 !important;
      border-radius: 8px;
      color: white; }
    .box-modal6-lastRow-no {
      width: 8.5rem;
      height: 2.6rem;
      border: 1px solid #039be5;
      font-size: 1.25rem !important;
      font-weight: 700 !important;
      color: #039be5;
      border-radius: 8px; }

.box-modal14 {
  width: 55vw !important;
  margin: auto;
  margin-top: 10% !important;
  border-radius: 15px !important;
  box-shadow: 0px 0px 12px 10px rgba(0, 0, 0, 0.1) !important;
  background-color: white;
  padding: 1.5rem; }
  @media screen and (max-width: 420px) {
    .box-modal14 {
      width: 95vw !important; } }
  @media screen and (min-width: 421px) and (max-width: 768px) {
    .box-modal14 {
      width: 75vw !important; } }
  @media screen and (min-width: 769px) and (max-width: 1080px) {
    .box-modal14 {
      width: 80vw !important; } }
  .box-modal14-lastColumn {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    color: #d4d4d4;
    font-size: 23px; }
  .box-modal14-middleColumn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column !important;
    padding-top: 1.5rem;
    padding-bottom: 2rem; }
  .box-modal14-middleColumn-text {
    font-weight: 500 !important;
    font-size: 1.5rem !important;
    padding-bottom: 0.75rem; }

/* Industry Section */
.profile-industry-type-section {
  padding: 1rem; }

.profile-industry-type-section {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  color: #5a5a5a;
  font-weight: 500; }

.profile-industry-type-section .form-group select {
  width: 100%;
  border: 1px solid grey;
  padding: 0.65rem 1rem;
  border-radius: 0.4rem;
  color: #7c7c7c;
  outline: none;
  font-size: 0.9rem; }

.profile-selected-industry {
  background-color: #e1f5fe;
  width: 100%;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  border-radius: 0.5rem; }

.profile-modal .modal-text {
  color: #5a5a5a;
  font-weight: bold;
  font-size: 1rem;
  margin: 0 3rem; }

@media (max-width: 991px) {
  .profile-industry-type-section {
    margin-bottom: 0; } }

@media (max-width: 576px) {
  .profile-industry-type-section {
    width: 100vw;
    margin-left: -15px; } }

@media only screen and (max-width: 767px) and (min-width: 577px) {
  .profile-industry-type-section {
    width: 95vw;
    margin-left: -15px; } }

.datalist {
  position: absolute;
  top: 5.35rem;
  left: 0;
  max-height: 150px;
  overflow-y: auto;
  padding: 1rem 0;
  width: 100%;
  background-color: white;
  border: 1px solid gray;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem; }

.datalist p {
  padding: 0.8rem 1rem;
  cursor: pointer; }

.datalist p:hover {
  font-weight: bold;
  background-color: #e2e2e2; }

.form-group input {
  width: 100%;
  border: 1px solid grey;
  padding: 0.65rem 1rem;
  border-radius: 0.4rem;
  color: #7c7c7c;
  outline: none;
  font-size: 0.9rem; }

.link-container {
  width: 300px;
  height: 350px;
  border: 1px solid #cecece;
  border-radius: 0.5rem; }

.link-image {
  width: 100%;
  height: 65%;
  border-radius: 0.5rem 0.5rem 0rem 0rem;
  object-fit: cover; }

.link-content {
  display: flex;
  flex-direction: column;
  padding: 15px; }

.link-content h1 {
  color: #3b3b3b;
  font-size: 16px;
  font-weight: 600; }

.link-content h2 {
  color: #5a5a5a;
  font-size: 12px;
  font-weight: 500; }

.link-content p {
  color: #5a5a5a;
  font-size: 10px;
  font-weight: 400; }

.profile-down .member-since {
  background: #eceff1;
  border: 1px solid #6e6d6d;
  border-radius: 10px;
  border-radius: 0.6rem;
  padding: 0.75rem 1rem;
  font-size: 1.25rem; }

.profile-down .member-since span {
  font-weight: bold; }

@media (max-width: 767px) {
  .profile-down .member-since {
    background-color: #eceff1; } }

/* Other Info */
.profile-all-other-info {
  padding: 1rem; }

.profile-all-other-info-top {
  display: flex;
  justify-content: space-between; }

.profile-all-other-info-top h1 {
  font-size: 1.25rem;
  color: #5a5a5a;
  font-weight: 500; }

.profile-all-other-info-top .add-other-info {
  font-size: 2.4rem;
  color: #0277bd;
  cursor: pointer; }

.all-other-info {
  border-radius: 0.75rem;
  border: 1px solid #cecece;
  overflow: hidden;
  margin-top: 0.5rem; }

.all-other-info .grey-background {
  background-color: #cfd8dc;
  height: 3.5rem;
  width: 100%; }

.all-other-info .title-section {
  padding: 1rem; }

.all-other-info .title-section h5 {
  font-weight: bold;
  color: #5a5a5a;
  line-height: 2.5rem; }

.all-other-info .title-section p {
  color: #4f4f4f; }

.all-other-info .images-section-cont {
  display: flex; }

.images-section-cont {
  display: flex;
  flex-direction: column; }

.scroll-img {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px; }

.images-section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  white-space: nowrap;
  gap: 10px;
  padding: 0px 8px; }

.pic-cont {
  height: 100px;
  width: 100px;
  cursor: pointer;
  position: relative;
  /* background: linear-gradient(220.6deg, #707070 23.01%, rgba(196, 196, 196, 0) 33.22%); */ }

.all-other-info .images-section figure {
  border-radius: 1rem;
  margin: 1rem 0.5rem;
  overflow: hidden; }

.all-other-info .images-section figure img {
  height: 100px;
  width: 100px; }

/* PDF Section */
.all-other-info .pdf-section {
  display: flex;
  padding: 0 15px; }

.all-other-info .pdf-section-none {
  display: none;
  width: 0;
  height: 0;
  border-width: 0; }

.all-other-info .pdf-section .each-pdf {
  border-radius: 0.5rem;
  margin: 1rem 0.5rem;
  overflow: hidden;
  height: 100px;
  width: 200px;
  position: relative; }

.all-other-info .pdf-section .each-pdf img {
  height: 100px;
  width: 200px; }

.all-other-info .pdf-section .each-pdf .pdf-banner {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
  background-color: #607d8b;
  color: white;
  display: flex;
  align-items: center; }

.all-other-info .pdf-section .each-pdf .pdf-banner svg {
  font-size: 1.5rem;
  margin-left: 0.6rem; }

.all-other-info .pdf-section .each-pdf .pdf-banner p {
  padding-left: 0.5rem; }

/* Links Section */
.all-other-info .links-section {
  margin: 1rem; }

.all-other-info .links-section li {
  margin: 0 1rem; }

.all-other-info .links-section li:first-child {
  margin-left: 0 !important; }

.each-link {
  /* width: 150px;
    height: 150px; */
  border-radius: 0.5rem;
  position: relative; }

/* .each-link .react_tinylink_card div:first-child {
    width: 100%;
}

.each-link .react_tinylink_card div:last-child {
    display: none;
} */
.each-link figure img {
  border-radius: 0.5rem;
  height: 100px;
  width: 100%; }

.each-link .link-banner {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
  background-color: #607d8b52;
  color: white;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  padding-left: 0.6rem; }

.each-Plink {
  display: flex;
  width: 181px;
  height: 88px;
  background-color: #64808e;
  border: 1px solid #64808e;
  border-radius: 5px;
  flex-direction: row;
  cursor: pointer; }

.each-svg {
  width: 25%;
  height: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-color: #64808e;
  position: relative; }

.each-title {
  width: 75%;
  height: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white; }

.links-section .react-multi-carousel-track {
  padding-bottom: 0 !important; }

.triangle {
  /* display: none !important; */
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 50px 50px 0;
  border-color: transparent #707070 transparent transparent;
  top: 0;
  position: absolute;
  margin-left: 50px;
  cursor: pointer; }

.triangle1 {
  /* display: none !important; */
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 50px 50px 0;
  border-color: transparent #707070 transparent transparent;
  top: 0;
  position: absolute;
  left: 0;
  cursor: pointer; }

.triangle2 {
  /* display: none !important; */
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 50px 50px 0;
  border-color: transparent #707070 transparent transparent;
  top: 0;
  position: absolute;
  right: 0;
  cursor: pointer; }

.triangle1 {
  /* display: none !important; */
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 50px 50px 0;
  border-color: transparent #707070 transparent transparent;
  top: 0;
  position: absolute;
  margin-left: 130px;
  cursor: pointer; }

.triangle2 {
  /* display: none !important; */
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 50px 50px 0;
  border-color: transparent #707070 transparent transparent;
  top: 0;
  position: absolute;
  margin-left: 142px;
  cursor: pointer; }

/* Modal */
.profile-modal .top-bar {
  background-color: #cfd8dc;
  color: #5a5a5a;
  padding: 0.75rem 1rem;
  text-align: center;
  font-weight: 600;
  min-width: 320px !important;
  width: 100%; }

.add-other-box .each-button {
  color: #5a5a5a;
  padding: 0.75rem 1rem;
  text-align: center;
  font-weight: 600;
  min-width: 320px !important;
  border-bottom: 1px solid #6e6d6d;
  cursor: pointer; }

.add-other-box .each-button:last-child {
  border-bottom: none; }

.add-other-box .each-button .icon-box {
  background-color: #b3e5fc;
  border-radius: 0.25rem;
  padding: 0.5rem; }

.add-other-box .each-button .each-heading {
  font-size: 1rem; }

.add-other-profile .p-6 {
  padding: 0 !important;
  border-radius: 1rem;
  overflow: hidden; }

.img-div {
  width: 130px;
  height: 130px; }

.delete > .delete-img {
  display: none;
  position: absolute;
  right: 10;
  top: 0; }

.delete > .delete-img:hover {
  display: flex;
  position: absolute;
  top: 0;
  right: 10; }

.delete-img-hide {
  display: none; }

.delete-img-show {
  display: flex;
  position: absolute;
  top: 5px;
  right: 10px; }

.delete-img-show-pdf {
  position: absolute;
  top: 5px;
  left: 160px; }

.add-other-image {
  display: flex;
  justify-content: center; }

.add-other-image {
  border: 1px solid grey;
  border-radius: 10px;
  height: 60px;
  width: 500px;
  margin: 0.5rem 0.5rem 1.5rem 0.5rem;
  background: #e6e6e6; }

.add-other-image input {
  display: none; }

.add-other-image label {
  background: #fafafa;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 7px;
  height: 40px;
  width: 150px;
  margin: 0.5rem;
  padding: 0.5rem;
  color: #6c6c6c;
  font-weight: 600;
  text-align: center;
  font-size: 16px; }

.other-image-span {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: #7c7c7c; }

.add-other-pdf {
  border: 1px solid grey;
  border-radius: 10px;
  height: 60px;
  width: 500px;
  margin: 0.5rem 0.5rem 1.5rem 0.5rem;
  background: #e6e6e6; }

.add-other-pdf input {
  display: none; }

.add-other-pdf label {
  background: #fafafa;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 7px;
  height: 40px;
  width: 150px;
  margin: 0.5rem;
  padding: 0.5rem;
  color: #6c6c6c;
  font-weight: 600;
  text-align: center;
  font-size: 16px; }

.add-other-pdf {
  display: flex;
  justify-content: center; }

.other-pdf-span {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: #7c7c7c; }

.slide-cont {
  position: absolute;
  top: 46%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; }

.slide-cont svg {
  width: 40px;
  height: 40px;
  cursor: pointer; }

.slide-cont svg:hover {
  color: white; }

.images-section::-webkit-scrollbar {
  height: 0.25rem;
  background-color: white;
  cursor: pointer;
  width: 0.5rem; }

.carousel-img {
  width: 40rem;
  height: 40rem; }

@media (max-width: 991px) {
  .profile-all-other-info {
    padding: 1rem 2rem;
    background-color: #eceff1;
    border-radius: 1.5rem;
    margin: 2rem 0; }
  .all-other-info .links-section li {
    margin: 0 0.5rem; }
  .all-other-info .images-section {
    overflow-x: scroll; }
  .all-other-info .images-section figure img {
    height: 80px;
    width: 80px; }
  .each-link figure img {
    height: 80px; } }

.react-horizontal-scrolling-menu--wrapper {
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 18px 0px; }

.react-horizontal-scrolling-menu--scroll-container {
  flex-basis: 100%;
  flex-shrink: 0;
  gap: 10px; }

/* Change position of container so arrows will be at top/bottom */
.react-horizontal-scrolling-menu--scroll-container {
  order: -1; }

/* @media (max-width: 576px) {
    .profile-all-other-info {
        padding: 1rem 1rem;
        border-radius: 1rem;
    }
} */
@media (max-width: 400px) {
  .profile-all-other-info {
    padding: 1rem 0.6rem; }
  .add-other-image-container {
    width: 360px; }
  .add-other-image {
    width: 315px; }
  .add-other-pdf-container {
    width: 360px; }
  .add-other-pdf {
    width: 315px; }
  .all-other-info .images-section {
    overflow-x: scroll; }
  .all-other-info .images-section figure img {
    height: 80px;
    width: 80px; }
  .images-section::-webkit-scrollbar {
    height: 0.25rem;
    background-color: white;
    cursor: pointer;
    width: 0.5rem; }
  .images-section::-webkit-scrollbar-thumb {
    border-radius: 0.2rem;
    background-color: #00076d; }
  .slide-cont {
    top: 40%; }
  .carousel-img {
    width: 20rem;
    height: 20rem; }
  .links-section {
    width: 20rem; }
  .pdf-section {
    width: 22rem; }
  .images-section {
    width: 22rem; } }

.basic-details-form .form-group .location .button {
  background-color: transparent;
  outline: none;
  width: calc(100% / 3); }

.basic-details-form .form-group .location .button:first-child {
  padding-right: 0.5rem; }

.basic-details-form .form-group .location .button:last-child {
  padding-left: 0.5rem; }

.basic-details-form .form-group .location .button input {
  display: none;
  outline: none; }

.basic-details-form .form-group .location .button label {
  display: block;
  background-color: transparent;
  outline: none;
  height: 2.8rem;
  width: 100%;
  border: 1px solid #8a8a8a;
  border-radius: 0.25rem;
  text-align: center;
  line-height: 2.8rem;
  vertical-align: middle; }

.basic-details-form .form-group .location .button .label-active {
  background-color: #253944;
  color: white; }

.basic-details-form .form-group select:focus {
  border: 0.1rem solid #067eed; }

.basic-details-form .form-group select {
  width: 100%;
  padding: 0.7rem 1rem;
  border: 0.1rem solid #8d8d8d;
  outline: none;
  border-radius: 0.25rem;
  background-color: transparent; }

.profile {
  padding: 0;
  max-width: calc(100%);
  margin: 0; }

.banner-instructions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 8px; }

.upload-banner-btn {
  display: flex;
  flex-direction: column;
  align-items: center; }

.profile-header {
  position: absolute;
  top: 0;
  left: 100px;
  background-color: white;
  height: 5rem;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  color: #37474f;
  justify-content: space-between;
  width: calc(100% - 100px); }

.profile-header button {
  background-color: #039be5;
  color: white;
  font-weight: 700;
  padding: 0.6rem 1rem;
  min-width: 10rem;
  border-radius: 0.5rem;
  margin-right: 2rem; }

.profile-header h1 {
  font-size: 1.5rem;
  font-weight: 600; }

.profle-header-img {
  display: flex;
  align-items: center; }

.profle-header-img img {
  height: 24px;
  width: 24px;
  margin-left: 0.5rem; }

.profile-images {
  position: relative; }

.profile-background {
  width: calc(100%);
  box-shadow: 0px -2px 4px #c4c4c4;
  overflow: hidden; }

.profile-background figure img {
  width: 100%;
  height: 300px;
  border-bottom-left-radius: 0.6rem;
  border-bottom-right-radius: 1rem; }

.profile-pictures {
  position: absolute;
  width: calc(100% - 135px);
  display: flex;
  justify-content: center;
  top: 270px; }

.profile-edit-icon {
  position: absolute;
  right: 5rem;
  font-size: 2.5rem;
  margin-top: 1rem;
  cursor: pointer; }

.profile-pictures-blank {
  height: 100px; }

.profile-pictures figure {
  padding: 0.3rem;
  background-color: white;
  border: 0.1rem solid #e0e0e0;
  border-radius: 50%;
  box-shadow: 0px 0px 12px 0px #00000040; }

.profile-pictures figure img {
  border-radius: 50%;
  width: 155px;
  height: 155px; }

.profile-content {
  width: 100%;
  display: flex;
  justify-content: center; }

.profile-content .profile-names h3 {
  text-align: center;
  color: #555555;
  font-size: 2.25rem;
  font-weight: 700; }

.profile-rating {
  display: flex;
  justify-content: center;
  margin: 1rem 0; }

.profile-rating span {
  font-size: 2rem; }

.horizontal-line {
  position: absolute;
  width: 100%;
  border-bottom: 0.1rem solid #a5a5a5;
  top: 100%; }

.profile-about {
  padding: 1rem;
  width: 100%;
  width: calc(100%);
  display: flex;
  justify-content: left;
  position: relative; }

.profile-about-blank {
  height: 7.5rem; }

.profile-about h4 {
  color: #555555;
  font-size: 1.5rem;
  font-weight: 600;
  padding-bottom: 1rem; }

/* Profile Down */
.profile-down {
  width: 100%;
  min-height: 300px;
  display: flex; }

.profile-down .profile-down-left {
  background-color: transparent;
  width: 60%;
  min-height: 300px;
  padding: 2rem; }

.profile-down .profile-down-right {
  width: 40%;
  /* background-color: #eceff1; */
  min-height: 300px;
  padding: 2rem 6rem 2rem 2rem; }

.profile-down .profile-down-left .left-info {
  background-color: white;
  width: 100%;
  min-height: 300px;
  border-radius: 1rem; }

.profile-down .profile-down-right .member-since {
  border: 1px solid #6e6d6d;
  border-radius: 0.6rem;
  padding: 0.75rem 1rem;
  font-size: 1.25rem;
  text-align: center; }

.profile-down .profile-down-right .member-since span {
  font-weight: bold; }

.profile-basic-details-header {
  height: 2.75rem;
  background-color: #cfd8dc;
  position: relative; }

.profile-down-details-edit {
  position: absolute;
  right: 1rem;
  top: 0.6rem;
  font-size: 1.5rem; }

.profile-down .profile-basic-details {
  border: 1px solid #6e6d6d;
  border-radius: 0.6rem;
  margin-top: 2rem;
  overflow: hidden; }

.profile-down .profile-basic-details-body {
  padding: 0 1rem; }

.profile-down .profile-basic-details-body h4 {
  line-height: 2.4rem;
  padding-left: 1rem; }

.profile-down .profile-basic-details-body h3 {
  line-height: 2.4rem;
  font-weight: 600; }

.profile-basic-details-body-border {
  border-right: 1px solid #6e6d6d; }

.hide-laptop {
  display: none; }

.hide-mobile {
  display: block; }

/* Modal */
.profile-modal .opacity-25 {
  background: rgba(96, 125, 139, 0.9) !important;
  opacity: 1 !important;
  left: 100px !important; }

.profile-modal .pointer-events-auto {
  left: 100px !important; }

.profile-modal .close-button button {
  background: #cfd8dc !important;
  border-radius: 1rem !important;
  color: #5a5a5a !important;
  min-width: 8rem !important;
  text-transform: capitalize !important; }

.profile-modal .save-button button {
  background: #067eed !important;
  border-radius: 1rem !important;
  color: white !important;
  font-weight: bold !important;
  min-width: 9rem !important;
  text-transform: capitalize !important; }

.profile-modal .modal-head {
  color: #5a5a5a;
  font-size: 1.25rem;
  font-weight: 600;
  min-width: 460px; }

.profile-modal .modal-head span {
  color: #b0b0b0;
  font-weight: 400;
  font-size: 1rem; }

.profile-modal .modal-input {
  width: 100%;
  background-color: #e6e6e6;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 1rem 0;
  border-radius: 0.75rem;
  outline: none; }

.profile-modal .modal-input:focus {
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.2); }

.profile-modal .mb-6 {
  margin-bottom: 0 !important; }

/* Profile Modal */
.change-profile-logo {
  display: flex;
  margin: 2rem 0; }

.change-profile-logo img {
  width: 100px;
  height: 100px;
  border-radius: 50%; }

.change-profile-logo div {
  display: flex;
  align-items: center; }

.change-profile-logo div input {
  display: none; }

.change-profile-logo div label {
  color: #067eed;
  border: 1px solid #8a8a8a;
  padding: 0.25rem 2rem;
  border-radius: 0.5rem; }

.change-profile-banner {
  display: flex;
  margin: 2rem 0; }

.change-profile-banner img {
  width: 300px;
  height: 100px;
  border-radius: 0.25rem; }

.change-profile-banner .input-field {
  display: flex;
  align-items: flex-start;
  flex-direction: column; }

.change-profile-banner div input {
  display: none; }

.change-profile-banner div label {
  color: #067eed;
  border: 1px solid #8a8a8a;
  padding: 0.25rem 3rem;
  border-radius: 0.5rem;
  margin: 0.4rem 0;
  width: 14rem; }

.change-profile-banner div button {
  color: #555555;
  border: 1px solid #8a8a8a;
  padding: 0.35rem 1rem;
  border-radius: 0.5rem;
  margin: 0.4rem 0;
  font-weight: bold;
  width: 14rem; }

.dont-images img {
  width: 300px;
  height: 100px;
  border-radius: 0.5rem;
  cursor: pointer; }

.company-name {
  margin: 2rem 0; }

.company-logo h1 {
  color: #5a5a5a;
  font-weight: bold; }

.company-name h1 {
  color: #5a5a5a;
  padding-bottom: 1rem;
  font-weight: bold; }

.company-name input {
  border: 1px solid #8d8d8d;
  border-radius: 3px;
  color: #7c7c7c;
  outline: none; }

.company-name input:focus {
  outline: none !important; }

.company-about {
  margin: 2rem 0; }

.company-about h1 {
  color: #5a5a5a;
  padding-bottom: 1rem;
  font-weight: bold; }

.company-about textarea {
  border: 1px solid #8d8d8d;
  border-radius: 3px;
  color: #7c7c7c;
  outline: none; }

.company-about textarea:focus {
  outline: none !important; }

/* Feedback Modal */
.endorse-modal .feedback-text textarea {
  margin-top: 1.25rem;
  outline: none;
  border: 1px solid #c3c3c3;
  border-radius: 0.25rem;
  padding: 1rem;
  width: 40vw;
  margin-bottom: 1rem;
  resize: none; }

.endorse-modal .feedback-header {
  color: #444444;
  font-size: 1.5rem;
  font-weight: 600; }

.justify-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  width: 700px; }

.row-banner-message h2 {
  color: #5a5a5a;
  font-size: 17px;
  font-weight: bold;
  padding-bottom: 8px; }

.row-banner-message span {
  color: #5a5a5a;
  font-size: 15px; }

/* @media (max-width: 1400px) {
    .profile-down .profile-down-right {
        padding: 2rem 2rem 2rem 2rem;
    }
}

@media (max-width: 1200px) {
    .profile-down .profile-down-left {
        padding: 2rem 1rem;
    }
    .profile-down .profile-down-right {
        padding: 2rem 1rem;
    }
} */
@media (max-width: 1240px) {
  .profile-down .profile-down-right {
    padding: 2rem 1rem 2rem 1rem !important; } }

@media (max-width: 991px) {
  .hide-laptop {
    display: block; }
  .hide-mobile {
    display: none; }
  .profile-mobile-margin {
    margin: 1rem 2rem; }
  .profile-top {
    /* border-radius: 0.5rem; */
    padding: 0px;
    border-bottom: 0.1rem solid #a5a5a5; }
  .profile-top-shadow {
    overflow: hidden;
    border-radius: 0.5rem;
    /* border: 1px solid #cecece; */
    /* box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15); */ }
  .profile-down {
    display: block; }
  .profile-down .profile-down-left {
    width: 100%; }
  .profile-down .profile-down-right {
    width: 100%; }
  /* .horizontal-line {
        display: none;
    } */
  /* .profile-about {
        width: 100%;
        padding: 1rem 1rem;
        border-top: 0.1rem solid #898989;
        box-shadow: none;
    } */ }

@media (max-width: 767px) {
  .change-profile-banner .input-field {
    display: flex;
    align-items: flex-start;
    flex-direction: column; }
  /* .horizontal-line {
        left: 0px;
        width: 100%;
    } */
  .banner-instructions {
    margin-left: 0px; }
  .upload-banner-btn {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
  .profile {
    padding: 0; }
  .change-profile-banner {
    min-width: 576px; }
  .profile-background {
    height: 160px; }
  .profile-down .profile-down-left {
    padding: 0.5rem; }
  .profile-pictures {
    width: 100%;
    top: 10rem; }
  .profile-pictures figure img {
    height: 100px;
    width: 100px; }
  .profile-header {
    top: 3rem;
    left: 0;
    width: 100%; }
  .profile-edit-icon {
    right: 3rem; }
  /* Modal */
  .profile-modal .opacity-25 {
    left: 0 !important; }
  .profile-modal .pointer-events-auto {
    left: 0 !important; }
  .profile-background figure img {
    object-fit: cover; } }

@media (max-width: 576px) {
  .profile-top {
    /* padding: 0.5rem 1rem; */ }
  .profile-down .profile-down-right {
    padding: 2rem 1rem; }
  .profile-mobile-margin {
    margin: 1rem 1rem; }
  .profile-background {
    height: 140px; }
  .profile-pictures {
    left: 0;
    width: 100%; }
  .profile-background figure img {
    object-fit: cover; } }

@media (max-width: 400px) {
  .hide-laptop {
    display: block; }
  .hide-mobile {
    display: none; }
  .profile-mobile-margin {
    margin: 1rem 0.6rem; }
  .profile-header button {
    background-color: #039be5;
    color: white;
    font-weight: 700;
    padding: 0.6rem 1rem;
    min-width: 8rem;
    border-radius: 0.5rem;
    margin-right: 1.5rem; }
  .profile-top {
    /* padding: 0.5rem 0.6rem; */ }
  .profile-modal .modal-head {
    min-width: 320px; }
  .change-profile-banner {
    flex-flow: column wrap; }
  .change-profile-banner .input-field {
    align-items: flex-start; }
  .justify-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    width: 357px; }
  .row-banner {
    width: 50%; }
  .modal-form textarea {
    max-width: 100vw !important; }
  .modal-form input {
    max-width: 100vw !important; }
  .endorse-modal .p-6 {
    padding: 0rem; }
  .change-profile-logo div {
    flex-direction: column; }
  .profile-background figure img {
    object-fit: cover; } }

.modal-wrapper > div > div {
  max-width: 100vw !important; }

input {
  max-width: 85vw !important; }

.profile-basic-details-header {
  height: 2.75rem;
  background-color: #cfd8dc;
  position: relative; }

.profile-down-details-edit {
  position: absolute;
  right: 1rem;
  top: 0.6rem;
  font-size: 1.5rem;
  cursor: pointer; }

.profile-down .profile-basic-details {
  border: 1px solid #6e6d6d;
  border-radius: 0.6rem;
  margin-top: 2rem; }

.profile-down .profile-basic-details-body {
  padding: 0 1rem; }

.profile-down .profile-basic-details-body h4 {
  line-height: 2.4rem;
  padding-left: 1rem; }

.profile-down .profile-basic-details-body h3 {
  line-height: 2.4rem;
  font-weight: 600; }

.profile-basic-details-body-border {
  border-right: 1px solid #6e6d6d; }

.basic-details-form {
  min-width: 360px;
  margin-bottom: 1.5rem; }

@media (max-width: 767px) {
  .profile-down .profile-basic-details {
    margin-top: 0;
    margin-bottom: 1rem; } }

.email-id {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  padding-right: 4px; }

@media (max-width: 1440px) {
  .email-id {
    width: 268px;
    height: 35px; } }

/* Profile Business Leaders */
.profile-business-leads {
  border: 1px solid #6e6d6d;
  border-radius: 0.6rem;
  margin-top: 2rem;
  overflow: hidden; }

.profile-business-header {
  display: flex;
  justify-content: center;
  color: #3b3b3b;
  font-size: 1.15rem;
  font-weight: 600;
  padding: 1rem;
  border-bottom: 1px solid #6e6d6d; }

/* .profile-business-persons {
    margin-bottom: 3rem;
} */
.profile-business-person {
  display: flex;
  align-items: center;
  min-height: 3.2rem;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #6e6d6d; }

.profile-business-person .flex figure {
  border-radius: 50%;
  overflow: hidden;
  margin-right: 2rem; }

.profile-business-person .flex figure img {
  width: 72px;
  height: 72px; }

.profile-business-person-details {
  display: flex;
  align-items: center; }

.profile-business-person-details div h3 {
  color: #555555;
  font-weight: bold;
  line-height: 2rem; }

.profile-business-person-details div h5 {
  color: #555555;
  line-height: 1.5rem; }

.select-leader label {
  font-weight: bold;
  padding: 0.5rem 0; }

.select-leader input {
  background: #e6e6e6;
  width: 100%;
  outline: none;
  border: none;
  border: 1px solid #acacac;
  border-radius: 0.75rem;
  padding: 0.5rem 1rem; }

.leader-image-upload {
  display: flex;
  height: 5.5rem; }

.leader-image {
  display: flex;
  padding-right: 1rem;
  padding-bottom: 2.5rem; }

.leader-image .file-box {
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 0.3rem;
  padding: 0.8rem 0.6rem;
  cursor: pointer; }

.leader-image .browse-files {
  display: flex;
  justify-content: flex-end;
  padding-right: 4px;
  margin-top: 2rem; }

.leader-image .browse-files div {
  color: #067eed;
  font-weight: 600;
  padding: 0.5rem 0.8rem;
  margin-top: 1rem;
  border: 0.1rem solid #8a8a8a;
  border-radius: 0.5rem;
  cursor: pointer; }

.leader-image div label {
  color: #5a5a5a;
  font-weight: bold; }

.leader-image center {
  height: 4rem; }

.leader-image center .blank-image {
  height: 5rem;
  width: 5rem;
  background-color: #818181;
  border-radius: 50%; }

.leader-image img {
  width: 5rem;
  height: 5rem;
  border-radius: 50%; }

.leader-image input {
  display: none; }

.leader-image span {
  font-size: 0.75rem;
  color: #474747; }

.delete-leader {
  cursor: pointer; }

.leader-modal-cont {
  height: auto;
  max-height: 250px;
  overflow-y: scroll; }

.leader-modal-cont::-webkit-scrollbar {
  display: none; }

@media (max-width: 400px) {
  .select-leader input {
    width: 325px; } }

@media (max-width: 500px) {
  .leader-image .browse-files div {
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center; } }

@media (max-width: 787px) {
  .leader-image {
    flex-direction: column;
    justify-content: center;
    align-items: center; } }

.profile-reviews {
  /* margin: 1rem; */
  margin-top: 1rem;
  /* border: 1px solid #cecece; */
  border-radius: 1rem;
  padding: 1rem; }

.profile-reviews .profile-reviews-heading {
  color: #5a5a5a;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem; }

.profile-reviews .profile-reviews-heading span {
  color: #5a5a5a;
  font-size: 0.9rem;
  font-weight: 500; }

/* Reviews */
.profile-review-card {
  padding: 1rem;
  border: 1px solid #cecece;
  border-radius: 0.75rem;
  margin: 1rem 0; }

.profile-review-card .top-section {
  display: flex; }

.profile-review-card .top-section img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 1rem; }

.profile-review-card .top-section .top-section-detail {
  display: flex;
  align-items: center; }

.profile-review-card .top-section .top-section-detail div h3 {
  color: #555555;
  font-weight: 500;
  padding-bottom: 0.3rem;
  font-size: 1.25rem; }

.see-all-reviews {
  color: #03a9f4;
  font-weight: bold;
  font-size: 14px; }

@media (max-width: 1024px) {
  .profile-reviews {
    margin-top: 1rem;
    padding: 0.5rem; } }

@media (max-width: 767px) {
  .profile-reviews {
    padding: 0.6rem;
    margin: 1rem 0 0 0; } }

/* Social Links */
.profile-social-links {
  border: 1px solid #6e6d6d;
  border-radius: 0.6rem;
  margin-top: 2rem;
  overflow: hidden; }

/* .profile-social-items {
    margin-bottom: 3rem;
} */
.max-w-screen {
  max-width: 100vw !important; }

.profile-social-item {
  display: flex;
  align-items: center;
  min-height: 3.2rem;
  padding: 0.75rem 0;
  border-bottom: 1px solid #6e6d6d;
  cursor: pointer;
  justify-content: space-between; }

.add-social-item {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-top: 10px; }

.profile-down-details-edit {
  position: absolute;
  right: 1rem;
  top: 0.6rem;
  font-size: 1.5rem;
  cursor: pointer; }

.profile-social-item .flex .profile-social-icon-wrapper {
  margin-left: 1rem;
  margin-right: 2rem;
  padding: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #b3e5fc;
  border-radius: 0.6rem;
  height: 2.8rem; }

.profile-social-item .flex .profile-social-icon-wrapper svg {
  font-size: 1.25rem; }

.profile-social-icon-name {
  display: flex;
  align-items: center;
  color: #546e7a;
  font-size: 1rem;
  font-weight: 600; }

.profile-social-add-button {
  padding: 1rem; }

.profile-social-add-button button {
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  color: #546e7a;
  font-weight: 500;
  font-size: 1.25rem;
  width: 100%; }

.profile-social-add-button button svg {
  color: #0277bd;
  font-size: 36px; }

.select-social label {
  font-weight: bold;
  padding: 0.5rem 0; }

.select-social input {
  width: 100%;
  outline: none;
  border: none;
  border: 1px solid #acacac;
  border-radius: 0.75rem;
  padding: 0.5rem 1rem; }

.link-modal-cont {
  height: 350px;
  overflow-y: scroll; }

.link-modal-cont::-webkit-scrollbar {
  display: none; }

.flex:hover {
  text-decoration: none;
  /* color: transparent; */
  color: inherit; }

.m-r-20px {
  margin-right: 20px; }

.w-380-px {
  width: 380px; }

/* ::-webkit-scrollbar {
	height: 0.25rem;
	background-color: white;
	cursor: pointer;
	width: 0.5rem;
} */
/* Track */
/* ::-webkit-scrollbar-track {
	border-radius: 0.25rem;
} */
/* Handle */
/* ::-webkit-scrollbar-thumb {
	border-radius: 0.2rem;
	background-color: rgb(0, 7, 109);
} */
/* Request Other to endorse */
.request-other-to-endorse {
  width: 100%;
  overflow: hidden;
  margin-top: 1rem; }

.request-other-to-endorse button {
  width: 100%;
  padding: 0.65rem 1rem;
  color: white;
  border-radius: 0.5rem;
  background-color: #0277bd;
  font-size: 20px; }

.input-section .input-email {
  border-radius: 6px;
  border: 1px solid #cecece; }

/* Modal */
.endorse-modal .opacity-25 {
  background: rgba(236, 239, 241, 0.8) !important;
  opacity: 1 !important; }

.endorse-modal .mb-6 {
  margin-bottom: 0 !important; }

.endorse-modal .endorse-body {
  width: 50vw; }

.endorse-body .top-heading {
  display: flex;
  align-items: center;
  margin-bottom: 15px; }

.endorse-body .top-heading .icon-wrapper {
  border: 1px solid #455a64;
  padding: 0.5rem;
  font-size: 1.6rem;
  border-radius: 50%; }

.endorse-body {
  padding-bottom: 1rem; }

.endorse-body .top-heading .icon-wrapper svg {
  color: #455a64; }

.endorse-body .top-heading h1 {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  color: #5b5b5b;
  font-size: 1.5rem; }

.endorse-body .get-free-ad-credit {
  color: #959595;
  font-size: 0.9rem;
  margin-top: 0.8rem; }

/* Chip Input */
.endorse-modal .WAMuiChipInput-inputRoot-2 {
  padding: 0 !important; }

.endorse-modal .WAMuiChipInput-root-1 {
  border: none !important;
  min-width: 300px;
  padding: 0 !important;
  margin: 0 !important; }

.endorse-modal .WAMuiChipInput-root-1 label {
  display: none; }

.endorse-modal .WAMuiChipInput-root-1 input {
  padding: 0.3rem 1rem; }

.endorse-modal .WAMuiChipInput-inputRoot-2.WAMuiChipInput-outlined-5 {
  padding-left: 0.5rem !important; }

.endorse-modal .MuiChip-root {
  background-color: #29b6f6 !important;
  color: white;
  margin-bottom: 0.3rem; }

.endorse-modal .MuiChip-root svg {
  color: white; }

/* Text Area */
.endorse-body textarea {
  resize: none;
  background-color: #f0f5fa;
  padding: 1rem;
  border-radius: 0.75rem;
  width: 100%;
  outline: none; }

.max-length-email-content {
  color: #ababab;
  font-size: 0.9rem;
  color: #5b5b5b;
  font-size: 0.9rem;
  text-align: justify; }

/* Share Link */
.endorse-body .share-this-link {
  color: #5b5b5b;
  font-size: 1rem; }

.endorse-body .bottom-share {
  align-items: center;
  margin-top: 2rem; }

.endorse-body .bottom-share button {
  font-weight: bold;
  color: #03a9f4;
  font-size: 18px; }

/* Buttons */
.endorse-modal .close-button button {
  background: #cfd8dc !important;
  border-radius: 0.6rem !important;
  color: #5a5a5a !important;
  min-width: 8rem !important;
  text-transform: capitalize !important; }

.endorse-modal .save-button button {
  background: #067eed !important;
  border-radius: 0.6rem !important;
  color: white !important;
  font-weight: bold !important;
  min-width: 8rem !important;
  text-transform: capitalize !important; }

@media (max-width: 767px) {
  .request-other-to-endorse {
    margin-top: 1rem; }
  .request-other-to-endorse button {
    display: flex;
    justify-content: center; }
  .endorse-modal .endorse-body {
    width: 60vw; } }

@media (max-width: 575px) {
  .endorse-modal .endorse-body {
    width: 90vw; }
  .input-section {
    max-width: 90vw !important; } }

@media (max-width: 400px) {
  .endorse-modal .endorse-body {
    width: 90vw; }
  .endorse-body {
    padding: 1.5rem; } }

@media (max-width: 460px) {
  .endorse-modal .endorse-body {
    width: 96vw;
    padding: 12px; } }

/* Tags Section */
.profile-tags-section {
  padding: 1rem; }

.profile-tags-section h1 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #5a5a5a;
  font-weight: 600; }

.profile-tags {
  display: inline-block;
  width: 100%;
  padding: 1rem 0; }

.profile-tags .profile-tags-each {
  background-color: #29b6f6;
  display: inline;
  border-radius: 1rem;
  padding: 0.3rem 0.8rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  color: white;
  padding-right: 2rem;
  position: relative;
  display: inline-block; }

.add-tag-btn svg {
  font-size: 30px; }

.profile-tags .profile-tags-each .tags-close-icon {
  color: white;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  cursor: pointer; }

.profile-tags .profile-tags-add {
  background-color: #546e7a;
  display: inline;
  border-radius: 1rem;
  padding: 0.2rem 0.8rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  color: white;
  padding-right: 2rem;
  position: relative;
  display: inline-block;
  cursor: pointer; }

.profile-tags .profile-tags-disable {
  background-color: #eeeeee;
  display: inline;
  border-radius: 1rem;
  padding: 0.2rem 0.8rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  color: #bdbdbd;
  padding-right: 2rem;
  position: relative;
  display: inline-block;
  cursor: pointer; }

.profile-modal .WAMuiChipInput-root-1 {
  border: none !important;
  min-width: 300px; }

.profile-modal .WAMuiChipInput-root-1 label {
  display: none; }

.profile-modal .WAMuiChipInput-inputRoot-2.WAMuiChipInput-outlined-5 {
  padding-top: 4px; }

.profile-modal .MuiChip-root {
  background-color: #546e7a !important;
  color: white; }

.profile-modal .MuiChip-root svg {
  color: white; }

@media (max-width: 991px) {
  .profile-tags {
    background-color: white;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
    border: none;
    border: 1px solid #ababab; } }

@media (max-width: 576px) {
  .profile-tags-section {
    width: 100vw;
    margin-left: -15px; } }

@media only screen and (max-width: 767px) and (min-width: 577px) {
  .profile-tags-section {
    width: 95vw;
    margin-left: -15px; } }

.company-activity-wrapper {
  padding: 1rem; }
  .company-activity-wrapper h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #5a5a5a;
    font-weight: 600; }
  .company-activity-wrapper .company-activity-box {
    border: 1px solid #3b3b3b;
    border-radius: 1rem;
    display: grid;
    grid-template-columns: 50% 50%; }
    .company-activity-wrapper .company-activity-box h3 {
      font-size: 1.25rem;
      font-weight: 500;
      color: #3b3b3b;
      margin-bottom: 1rem; }
    .company-activity-wrapper .company-activity-box .activity-comp-icon {
      width: 20px;
      height: 20px;
      display: inline-block;
      border-radius: 50%;
      overflow: hidden; }
    .company-activity-wrapper .company-activity-box .company-you-backed {
      border-right: 1px solid #5a5a5a;
      padding: 1rem; }
    .company-activity-wrapper .company-activity-box .comp-activity-card {
      background: #ddd;
      border-radius: 0.5rem;
      padding: 0.6rem; }
      .company-activity-wrapper .company-activity-box .comp-activity-card .comp-info p {
        background: #fff;
        padding: 0.6rem;
        border-radius: 0.6rem; }
    .company-activity-wrapper .company-activity-box .company-backed-you {
      padding: 1rem; }
