body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f9;
  margin: 0;
  padding: 0;
}

h1,
h2 {
  color: #333;
}

h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

h2 {
  font-size: 20px;
  /* margin-top: 20px; */
}

.request-item {
  background-color: #ddebff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: 8px solid transparent;
  width: 50%;
}

.request-item-title {
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 40px;
  padding: 8px 16px;
  display: flex;
  gap: 8px;
  width: 50%;
  align-items: center;
  transition: background-color 0.3s;
}

.underline {
  display: inline-block;
  border-bottom: 0px solid #000; /* Black underline */
  /* padding-bottom: 2px; */
  /* margin-bottom: 8px; */
}

.black-dot {
  height: 12px;
  width: 12px;
  background-color: #000;
  border-radius: 50%;
  display: inline-block;
}

.request-item.pending {
  /* border-left-color: #00f; */
}

.request-item.approved {
  /* border-left-color: #28a745; */
}

.request-item.waitlist {
  /* border-left-color: #ffc107; */
}

.request-details {
  flex-grow: 1;
}

.request-details p {
  margin: 4px 0;
  color: #333;
}

.request-actions {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
}

.request-button {
  /* background-color: #007bff; */
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.request-button:hover {
  background-color: #0056b3;
}

.request-button.confirm {
  background-color: #0171bb;
}

.request-button.confirm:hover {
  background-color: #184c78;
}

.request-button.waitlist {
  background-color: #545454;
  color: #fff;
}

.request-button.waitlist:hover {
  background-color: #3b3838;
}

.request-button.delete {
  background-color: #545454;
}

.request-button.delete:hover {
  background-color: #3b3838;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .modal-content {
  background: #e9e8e8;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  text-align: center;
} */

.modal-content h2 {
  margin-top: 0;
}

.modal-content p {
  margin: 10px 0;
}

.modal-content button {
  margin-top: 20px;
}

.modal-content button:first-of-type {
  margin-right: 10px;
}

/* card style */

.card-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* width: 100%; */
  /* height: 100%; */
  padding: 20px;
  box-sizing: border-box;
}

.card {
  width: 100%;
  padding: 10px;
  max-width: 600px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #e3f2fd;
  font-family: Arial, sans-serif;
  color: #000;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.company-logo {
  width: 40px;
  height: 40px;
  background-color: #000;
  border-radius: 50%;
  margin-right: 10px;
}

.company-name {
  font-weight: bold;
  color: #1a237e;
}

.confirmation {
  color: #000;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  gap: 5px; /* Adds space between the icon and the text */
}

.confirmation .icon {
  color: #4caf50;
  font-size: 1.5em; /* Adjust the size of the icon as needed */
}

.confirmation .icon-waitlist {
  color: #000;
  font-size: 1.5em; /* Adjust the size of the icon as needed */
}

.card-body {
  padding: 10px;
  background-color: #ffffff80;
  border-radius: 8px;
  max-width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.event-title {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 5px;
}

.user-name {
  font-size: 1.1em;
  font-weight: bold;
  /* margin-bottom: 2px; */
}
.user-email {
  font-size: 0.9em;
  margin-bottom: 2px;
}

.job-title {
  font-size: 1.1em;
  margin-bottom: 2px;
}

.event-date {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.event-description {
  font-size: 1em;
  color: #424242;
}

.card-footer {
  padding: 10px;
  text-align: right;
  font-size: 0.9em;
  color: #616161;
}

.right-pan {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
}

.confirmation-title {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.section-title {
  font-size: 1.6rem;
  font-weight: bold;
  /* margin-bottom: 5px; */
}

.container {
  display: flex;
  justify-content: flex-start;
  padding: 20px;
}

.list-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.list {
  list-style-type: none;
  margin: 0 20px;
  padding: 0;
}

@media (max-width: 480px) {
  .card {
    max-width: 100%;
  }
  body {
    padding: 0;
  }
  .list-container {
    flex-wrap: wrap;
  }
  .list {
    margin: 0;
  }
  .card-container {
    padding: 10px;
  }
}
