.dashboard-container {
    background-color: #f7f7f7;
    border-radius: 15px;
    padding: 20px;
    color: #333;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .dashboard-header h1 {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
  }
  
  .dashboard-header span {
    font-size: 18px;
    font-weight: normal;
    color: #666;
  }
  
  .dashboard-content {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .chart-container {
    flex: 1;
    /* background-color: white; */
    /* border-radius: 10px; */
    padding: 20px;
    /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
  }
  
  .chart-container h2 {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  
  .chart {
    height: 300px;
  }

  .spacer {
    height: 200px;
  }






  .badge-cards {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .badge-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f0f0f0;
    border-radius: 10px;
    padding: 10px 20px;
  }
  
  .badge-info h3 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .badge-info p {
    margin: 0;
    font-size: 1rem;
    color: #666;
  }
  
  .badge-icon {
    margin: 10;
    width: 100px;
    height: 40px;
    border-radius: 20%;
    background: #007bff;
    padding: 5px;
  }
  




  /* new  */

  /* .dashboard-container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .dashboard-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .dashboard-content {
    display: flex;
    justify-content: space-between;
  }
  
  .chart-container {
    flex: 2;
  }
  
  .badge-cards {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .badge-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f0f0f0;
    border-radius: 10px;
    padding: 10px 20px;
  }
  
  .badge-info h3 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .badge-info p {
    margin: 0;
    font-size: 1rem;
    color: #666;
  }
  
  .badge-icon img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #007bff;
    padding: 5px;
  } */