.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-container {
  background: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  width: 600px;
  text-align: center;
}

.count-section {
  margin-bottom: 20px;
  margin-top: 20px;
}

.count-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.count-row label {
  flex: 1;
  font-weight: bold;
  text-align: left;
}

.count-row input[type="number"],
.count-row input[type="text"] {
  width: 60px;
  text-align: center;
  margin: 0 10px;
}

.button-section {
  display: flex;
  justify-content: space-between;
  margin-top: 20;
}

.cancel-button {
  background: #fff;
  border: 1px solid #007bff;
  color: #007bff;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

/* .save-button {
    background: #007bff;
    border: 1px solid #007bff;
    color: #fff;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
  } */

.cancel-button:hover,
.save-button:hover {
  opacity: 0.9;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordian-details-container {
  align-items: flex-start;
  justify-content: flex-start;
}
