.top-nav-menu {
  max-height: calc(100% - 10.5rem);
  overflow-y: auto;
}

.disabled-slot-confirm-button {
  cursor: default;
  background: #bfe5fb;
}

.request-item-title_spotlight {
  display: flex;
  align-items: center;
  gap: 1rem;
  background: #fff;
  padding: 6px 10px;
  border-radius: 24px;
  max-width: 16rem;
}

.event-notification-box {
  width: 80%;
}

.event-notification-card {
  width: 100%;
}

.profile-business-header {
  background: #d9d9d8;
  border-bottom: none;
  padding: 0;
}

.profile-basic-details-body-border {
  border-right: none;
}

.profile-basic-details-body {
  background: #f6f6f6;
}

.profile-basic-details-header {
  display: flex;
  align-items: center;
  width: 100%;
  background: #d9d9d8;
  justify-content: space-between;
  padding: 1rem;
  height: 100%;
}

.profile-down-details-edit {
  position: static;
}

.profile-down .profile-basic-details {
  border: none;
}

.profile-business-leads .profile-business-header {
  padding: 1rem;
}

.company-activity-wrapper .company-activity-box h3 {
  font-size: 1.5rem;
  line-height: normal;
  font-weight: 500;
  text-align: center;
  color: #3b3b3b;
  font-family: "poppins", sans-serif;
  margin-bottom: 1rem;
}

.profile-business-persons,
.profile-social-add-button {
  background: #f6f6f6;
}

.profile-business-leads {
  border: none;
}

.profile-business-person-details div h3 {
  line-height: normal;
}

figure.leadership-profile-img {
  height: auto;
  width: 50px;
}

@media (max-width: 991px) {
  .profile-tags {
    padding: 1rem;
  }
  .profile-all-other-info {
    padding: 1rem;
  }
}

@media (max-width: 959px) {
  .profile-pictures {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .event-notification-box {
    width: 100%;
  }
  .event-members-table {
    width: 100% !important;
    overflow: auto;
  }
  .company-activity-wrapper .company-activity-box {
    grid-template-columns: 100%;
  }
  .company-activity-wrapper .company-activity-box .company-you-backed {
    border-right: none;
  }
}
