.auto-complete {
    position: relative;
    width: 100%;
  }
  
  .auto-complete-input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  .auto-complete-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 200px;
    overflow-y: auto;
    background: white;
    border: 1px solid #ddd;
    border-top: none;
    z-index: 1000;
  }
  
  .auto-complete-item {
    padding: 8px;
    cursor: pointer;
  }
  
  .auto-complete-item:hover {
    background: #f0f0f0;
  }  