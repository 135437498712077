.box-modal9 {
  //   position: absolute !important;
  //   top: 50% !important;
  //   left: 50% !important;
  //   transform: translate(-50%, -50%) !important;
  width: 41rem !important;
  margin-top: 13%;
  margin-bottom: 5%;
  margin-left: 28%;
  @media screen and (max-width: 420px) {
    width: 100vw !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
  }
  @media screen and (min-width: 421px) and (max-width: 768px) {
    width: 41rem !important;
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 8%;
  }
  @media screen and (min-width: 769px) and (max-width: 1080px) {
    width: 41rem !important;
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 12%;
  }
  border-radius: 15px !important;
  box-shadow: 0px 0px 12px 10px rgba(0, 0, 0, 0.1) !important;
  background-color: white;
  padding: 1.5rem;
  &-header {
    font-size: 2.25rem !important;
    font-weight: 500 !important;
    @media screen and (max-width: 420px) {
      font-size: 1.25rem !important;
    }
  }
  &-label {
    color: #616161;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0.45rem !important;
  }
  &-field {
    border-radius: 8px;
    border: 1px solid #818181 !important;

    width: 100%;
    height: 3.125rem;
    padding-left: 10px;
  }

  &-lastrow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-button1 {
      width: 8.5rem;
      height: 2.5rem;
      border-radius: 8px;
      font-size: 1.25rem;
      font-weight: 700;
      color: #039be5;
      border: 1px solid #039be5;
    }
    &-button2 {
      width: 8.5rem;
      height: 2.5rem;
      border-radius: 8px;
      font-size: 1.25rem;
      font-weight: 700;
      background-color: #039be5;
      color: white;
      border: 1px solid #039be5;
    }
  }
}
