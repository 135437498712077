.box-modal13 {
  //   position: absolute !important;
  //   top: 50% !important;
  //   left: 50% !important;
  //   transform: translate(-50%, -50%) !important;
  max-width: 29rem !important;
  margin-top: 13%;
  margin-bottom: 5%;
  margin-left: 35%;
  @media screen and (max-width: 420px) {
    width: 100vw !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
  }
  @media screen and (min-width: 421px) and (max-width: 768px) {
    width: 41rem !important;
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 15%;
  }
  @media screen and (min-width: 769px) and (max-width: 1080px) {
    width: 41rem !important;
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 25%;
  }
  border-radius: 15px !important;
  box-shadow: 0px 0px 12px 10px rgba(0, 0, 0, 0.1) !important;
  background-color: white;
  padding: 1.5rem;

  &-lastRow {
    display: flex;
    justify-content: space-between;

    padding-top: 8%;
    padding-bottom: 1%;

    &-yes {
      width: 11rem;
      height: 2.6rem;
      background-color: #039be5;
      font-size: 1.25rem !important;
      font-weight: 700 !important;
      border-radius: 8px;
      color: white;
    }
    &-no {
      width: 8.5rem;
      height: 2.6rem;
      border: 1px solid #039be5;
      font-size: 1.25rem !important;
      font-weight: 700 !important;
      color: #039be5;
      border-radius: 8px;
    }
  }
}
